// Markdown styling
.package-description {
    
    img {
        max-width: 100%;
    }
    
    table {
        margin-bottom: 1rem;
        thead {
            tr {
                th, td {
                    border: 1px solid rgba($color: #000000, $alpha: .2);
                    padding: .2rem 1rem;
                    background: rgba($color: #000000, $alpha: .05);
                    font-weight: bold;
                }
            }
        }
        tbody {
            tr {
                th, td {
                    border: 1px solid rgba($color: #000000, $alpha: .2);
                    padding: .2rem 1rem;
                }
            }
        }
        tfoot {
            tr {
                th, td {
                    border: 1px solid rgba($color: #000000, $alpha: .2);
                    padding: .2rem 1rem;
                    background: rgba($color: #000000, $alpha: .05);
                    font-weight: bold;
                }
            }
        }
        
    }
}

.ul-inline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        display: inline;
        padding: .3rem;
        a {
            text-decoration: none;
        }
    }
}
.substr {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
}

.menu-notification {
    a {
        &::after {
            display: none;
        }
    }
}
.form-control {
    &:focus {
        box-shadow: none;
    }
}
.main-content {
    min-height: 1000px;
}

.profile-package-items {

    .card-columns {
        @include media-breakpoint-only(sm) {
          column-count: 2;
        }
        @include media-breakpoint-only(lg) {
            column-count: 3;
          }
        @include media-breakpoint-only(xl) {
          column-count: 3;
        }
      }
}

.card-columns {
    @include media-breakpoint-only(sm) {
      column-count: 2;
    }
    @include media-breakpoint-only(lg) {
        column-count: 3;
      }
    @include media-breakpoint-only(xl) {
      column-count: 4;
    }
  }

.ladmin-pagination {
    nav {
        display: inline-block;
    }
}

.footer-app {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            display: inline;
            padding-left: 3px;
            border-left: solid 1px rgba($color: #000000, $alpha: .5);
            &:first-child {
                border: none;
            }
            a {
                display: inline-block;
                line-height: 17px;
                padding: 0 .5rem;
            }
        }
    }
}